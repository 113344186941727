import React from 'react'
import PropTypes from 'prop-types'
import { getYoutubeId } from '../../helpers/utilities'
import StyledFeatureGrid from './style'
import { Grid } from '../Grid'
import { FeatureCard } from '../FeatureCard'

const FeatureGrid = props => {
  const { features } = props
  return (
    <StyledFeatureGrid>
      <Grid col={3}>
        {features.map((item, index) => {
          if (!item) return null

          const { title, acfAttributes } = item
          const {
            featureText,
            featureImage,
            featureLargeImage,
            featureYoutubeUrl,
            featureLink,
          } = acfAttributes

          const videoId = getYoutubeId(featureYoutubeUrl)
          const videoUrl = videoId
            ? `https://www.youtube.com/embed/${videoId}`
            : null

          return (
            <FeatureCard
              key={index}
              text={featureText}
              image={
                featureImage && featureImage.makeImage
                  ? featureImage.makeImage
                  : null
              }
              heading={title}
              largeImage={
                featureLargeImage && featureLargeImage.makeImage
                  ? featureLargeImage.makeImage
                  : null
              }
              video={videoUrl}
              link={featureLink}
            />
          )
        })}
      </Grid>
    </StyledFeatureGrid>
  )
}

FeatureGrid.propTypes = {
  features: PropTypes.array,
}

export { FeatureGrid }
