import styled from 'styled-components'
import { spacing, mq } from '../../styles/utilities/variables'

const StyledPageContent = styled.section`
  padding: ${spacing.xxxLarge} 0 ${spacing.xxLarge} 0;

  @media ${mq.tablet} {
    padding: ${spacing.xLarge} 0 ${spacing.xLarge} 0;
  }

  @media ${mq.mobile} {
    padding: ${spacing.medium} 0 ${spacing.medium} 0;
  }
`

export default StyledPageContent
