import React from 'react'
import PropTypes from 'prop-types'
import StyledSectionHeader from './style'

const SectionHeader = ({ text }) => {
  return (
    <StyledSectionHeader>
      <p>{text}</p>
    </StyledSectionHeader>
  )
}

SectionHeader.propTypes = {
  text: PropTypes.string,
}

export { SectionHeader }
