import styled, { css } from 'styled-components'
import { spacing, typography, colors } from '../../styles/utilities/variables'

const StyledFeatureCard = styled.div`
  margin-bottom: ${spacing.medium};
`

StyledFeatureCard.ImageWrapper = styled.div`
  position: relative;
  i {
    color: ${colors.primaryDarkBlue};
  }
`

StyledFeatureCard.Heading = styled.h4`
  margin-top: ${spacing.base};
  text-transform: uppercase;
  line-height: ${typography.sizes.medium};
  letter-spacing: 0.02em;
`

StyledFeatureCard.Text = styled.p`
  line-height: 150%;
  margin-top: ${spacing.xSmall};
`

StyledFeatureCard.Image = styled.figure`
  height: 30rem;
  background-size: cover;
  background-position: center;
  min-width: 25rem;
`

StyledFeatureCard.MakeImageWrapper = styled.div`
  position: relative;
  height: 30rem;
  min-width: 25rem;
  ${props =>
    props.onClick &&
    css`
      cursor: zoom-in;
    `}
`

StyledFeatureCard.LightBoxTrigger = styled.button`
  background: white;
  font-size: 0;
  border: none;
  outline: none;
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 5px;
  //transform: rotate(90deg);
`

StyledFeatureCard.IconWrapper = styled.div``

StyledFeatureCard.LightBoxTriggerVid = styled.button`
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  font-size: 0;
  border: none;
  outline: none;
  position: absolute;
  left: 50%;
  top: 50%;
  padding: 5px;
  transform: translateY(-50%) translateX(-50%);
  i {
    color: ${colors.primaryRed};
  }
`

export default StyledFeatureCard
