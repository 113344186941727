import styled from 'styled-components'
import {
  spacing,
  mq,
  colors,
  maxWidths,
  typography,
} from '../../styles/utilities/variables'

const StyledContent = styled.div`
  line-height: 1.5;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${typography.primaryFont};
    margin-bottom: ${spacing.medium};
    line-height: 1.15;
    max-width: ${maxWidths.base};
  }

  p,
  ul,
  ol {
    margin-bottom: ${spacing.medium};
    line-height: 1.5;
    max-width: ${maxWidths.base};
  }

  ul,
  ol {
    padding-left: ${spacing.xLarge};

    @media ${mq.mobile} {
      padding-left: ${spacing.large};
    }
  }

  em {
    font-style: italic;
  }

  a {
    color: ${colors.primaryDarkBlue};
  }
`

export default StyledContent
