import React from 'react'
import PropTypes from 'prop-types'

import '../../../node_modules/basiclightbox/dist/basicLightbox.min.css'
import * as basicLightbox from 'basiclightbox'

import StyledFeatureCard from './style'
import { Icon } from '../Icon'
import { getUrl } from '../../helpers/url'
import { TextLink } from '../TextLink'
import { Link } from 'gatsby'
import { MakeImage } from '../MakeImage'

const FeatureCard = props => {
  const { heading, text, image, largeImage, video, link } = props

  const openLightBoxImage = url => {
    basicLightbox
      .create(`<img src="${url}">`, {
        className: 'eco-lightbox',
      })
      .show()
  }
  const openLightBoxVideo = url => {
    basicLightbox
      .create(
        `<div class="iframe-wrapper"><iframe
      src="${url}"
      frameborder='0'
      allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
      allowfullscreen
    ></iframe></div>`,
        {
          className: 'eco-lightbox eco-lightbox-video',
        }
      )
      .show()
  }

  const doOpenLightbox = largeImage
    ? () => {
        openLightBoxImage(largeImage.url)
      }
    : null

  return (
    <StyledFeatureCard>
      <StyledFeatureCard.ImageWrapper>
        {image && (
          <>
            {link && (
              <Link to={getUrl(link.url)}>
                <StyledFeatureCard.MakeImageWrapper>
                  <MakeImage makeImage={image} coverBackground />
                </StyledFeatureCard.MakeImageWrapper>
              </Link>
            )}

            {!link && (
              <StyledFeatureCard.MakeImageWrapper onClick={doOpenLightbox}>
                <MakeImage makeImage={image} coverBackground />
              </StyledFeatureCard.MakeImageWrapper>
            )}
          </>
        )}

        {largeImage && !video && (
          <StyledFeatureCard.LightBoxTrigger
            onClick={() => {
              openLightBoxImage(largeImage.url)
            }}
          >
            <Icon size={25} icon={'search'} />
          </StyledFeatureCard.LightBoxTrigger>
        )}

        {video && (
          <StyledFeatureCard.LightBoxTriggerVid
            onClick={() => {
              openLightBoxVideo(video)
            }}
          >
            <Icon size={75} icon={'play_arrow'} />
          </StyledFeatureCard.LightBoxTriggerVid>
        )}
      </StyledFeatureCard.ImageWrapper>
      <StyledFeatureCard.Heading>{heading}</StyledFeatureCard.Heading>
      <StyledFeatureCard.Text>{text}</StyledFeatureCard.Text>

      {link && link.url && (
        <TextLink to={getUrl(link.url)} text={link.title} underline />
      )}
    </StyledFeatureCard>
  )
}

FeatureCard.propTypes = {
  imageWrapper: PropTypes.any,
  image: PropTypes.object,
  largeImage: PropTypes.object,
  heading: PropTypes.string,
  text: PropTypes.string,
  LightBoxTrigger: PropTypes.any,
}

export { FeatureCard }
