import React from 'react'
import PropTypes from 'prop-types'
import StyledPageContent from './style'

const PageContent = props => {
  const { children } = props
  return <StyledPageContent>{children}</StyledPageContent>
}

PageContent.propTypes = {
  children: PropTypes.any,
}

export { PageContent }
