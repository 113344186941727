import React, { useState } from 'react'
import PropTypes from 'prop-types'
import StyledColorPicker from './style'
import { useTranslation } from '../../hooks/useTranslation'

const ColorPicker = ({
  small = false,
  interactive = false,
  variations,
  changeHandler = false,
}) => {
  const [isSelected, setIsSelected] = useState(0)

  const getTranslation = useTranslation()

  const Component = interactive
    ? StyledColorPicker.Input
    : StyledColorPicker.Color

  const onColorChange = index => {
    setIsSelected(index)

    // Pass on if available
    if (changeHandler) {
      changeHandler(variations[index].variationId)
    }
  }

  return variations && variations.length > 0 ? (
    <StyledColorPicker>
      {interactive &&
        variations[isSelected].attributes &&
        variations[isSelected].attributes.nodes &&
        variations[isSelected].attributes.nodes.length > 0 && (
          <StyledColorPicker.Selected>
            {getTranslation('colorUppercaseText')}:{' '}
            {getTranslation(variations[isSelected].attributes.nodes[0].value)}
          </StyledColorPicker.Selected>
        )}
      <StyledColorPicker.Colors>
        {variations.map((variation, index) => {
          const { variationId, attributes } = variation

          if (attributes && attributes.nodes && attributes.nodes.length > 0) {
            const color = attributes.nodes[0].value
            const colorConstant = color.toUpperCase().replace(/\s/g, '')
            return (
              <React.Fragment key={variationId}>
                <Component
                  id={'color-' + variationId}
                  type='radio'
                  checked={index === isSelected}
                  value={color}
                  color={colorConstant}
                  small={small}
                  onChange={() => onColorChange(index)}
                />
                {interactive && (
                  <StyledColorPicker.Label
                    color={colorConstant}
                    htmlFor={'color-' + variationId}
                  />
                )}
              </React.Fragment>
            )
          } else {
            return null
          }
        })}
      </StyledColorPicker.Colors>
    </StyledColorPicker>
  ) : null
}

ColorPicker.propTypes = {
  colors: PropTypes.array,
  interactive: PropTypes.bool,
  small: PropTypes.bool,
  changeHandler: PropTypes.func,
}

export { ColorPicker }
