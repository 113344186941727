import styled from 'styled-components'
import { colors, typography } from '../../styles/utilities/variables'

const StyledSectionHeader = styled.div`
  display: flex;
  align-items: center;

  p {
    color: ${colors.primaryDarkBlue};
    text-transform: uppercase;
    font-size: ${typography.sizes.medium};
    line-height: 27px;
    margin: 0;
    margin-top: 3px;
    letter-spacing: 0.03em;
  }

  &:before {
    content: '';
    display: inline-block;
    width: 5px;
    height: 27px;
    background-color: ${colors.primaryRed};
    margin-right: 8px;
    transform: translateY(-1px);
  }
`

export default StyledSectionHeader
