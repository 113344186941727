import React from 'react'
import PropTypes from 'prop-types'
import StyledIndentWrapper from './style'
import { Grid } from '../../components/Grid'
import { Col } from '../../components/Col'

const IndentWrapper = props => {
  const { children, col } = props

  return (
    <StyledIndentWrapper>
      <Grid col={col}>
        <Col column={'2 / -1'}>{children}</Col>
      </Grid>
    </StyledIndentWrapper>
  )
}

IndentWrapper.propTypes = {
  children: PropTypes.any,
  col: PropTypes.number,
}

export { IndentWrapper }
