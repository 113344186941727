import styled, { css } from 'styled-components'
import { colors, spacing, typography } from '../../styles/utilities/variables'
import StyledColorPicker from '../ColorPicker/style'
import StyledHeading from '../Heading/style'
import StyledText from '../Text/style'

const StyledProductCard = styled.div`
  background-color: rgba(196, 196, 196, 0.1);
  display: flex;
  flex-direction: column;
  cursor: pointer;
  height: 100%;

  & ${StyledHeading.h4} {
    text-transform: uppercase;
    font-family: ${typography.primaryFont};
    letter-spacing: 0.05rem;
    line-height: 1.3;
    margin-bottom: ${spacing.xxSmall};
  }

  & ${StyledText} {
    font-size: ${typography.sizes.small};
    line-height: 1.5;
  }

  ${StyledColorPicker} {
    margin-bottom: ${spacing.small};
  }

  ${props =>
    props.atComparePage &&
    css`
      position: relative;
      cursor: default;
      height: 100%;

      i {
        background-color: rgba(0, 49, 69, 0.7);
        border-radius: 100%;
        cursor: ${props => (props.isRemoveDisabled ? 'default' : 'pointer')};
        color: white;
        padding: 1px;
        position: absolute;
        top: 1rem;
        right: 1rem;
      }
    `}
`

StyledProductCard.Body = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 1rem 1rem 1rem;
`

StyledProductCard.ImageWrapper = styled.div`
  width: 100%;
  margin-bottom: ${spacing.small};
`

StyledProductCard.Image = styled.img`
  width: 100%;
  margin-bottom: ${spacing.small};
`

StyledProductCard.Percentage = styled.p`
  font-weight: bold;
  color: ${colors.black};
  margin-bottom: ${spacing.xSmall};
`

StyledProductCard.StockMessage = styled.p`
  color: ${colors.black};
  opacity: .6;
  font-size: ${typography.sizes.small};
  line-height: 1.3;
`

StyledProductCard.SpaceFiller = styled.div`
  height: 2rem;
`

StyledProductCard.Actions = styled.div`
  height: 4rem;
  display: flex;
  flex-direction: column-reverse;
  margin-top: auto;

  a {
    align-self: flex-end;
  }
`

export default StyledProductCard
