import styled from 'styled-components'
import { mq } from '../../styles/utilities/variables'
import StyledGrid from '../../components/Grid/style'
import StyledCol from '../../components/Col/style'

const StyledIndentWrapper = styled.div`
  > ${StyledGrid} {
    > ${StyledCol} {
      @media ${mq.tablet} {
        grid-column: 1 / -1;
      }
    }
  }
`

export default StyledIndentWrapper
