import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import noImageFallback from '../../assets/images/noImageFallback.png'
import {
  addProductToCompare,
  AppContext,
  removeProductFromCompare,
} from '../../config/context'
import { PRODUCT_TYPES } from '../../helpers/constants'
import { getUrl } from '../../helpers/url'
import { formatPrice } from '../../helpers/utilities'
import { useTranslation } from '../../hooks/useTranslation'
import { Button } from '../Button'
import { ColorPicker } from '../ColorPicker'
import { Form } from '../Form'
import { Heading } from '../Heading'
import { Icon } from '../Icon'
import { MakeImage } from '../MakeImage'
import { Text } from '../Text'
import StyledProductCard from './style'

const ProductCard = props => {
  const [state, dispatch] = useContext(AppContext)
  const { compareIsEnabled, productsToCompare, stockStatus } = state
  const getTranslation = useTranslation()

  const {
    product,
    compareAllowed = false,
    atComparePage = false,
    percentage,
  } = props

  const {
    id,
    type,
    productId,
    name,
    uri,
    price,
    image,
    acfAttributes: { productSerie, partialPayment },
    variations,
    productTags,
  } = product

  const productStockStatus = stockStatus.length
    ? stockStatus.filter(p => p.id === productId)[0]
    : null

  // const productImage =
  //   image && image.sourceUrl ? image.sourceUrl : noImageFallback
  const productImage =
    image && image.makeImageSmall ? image.makeImageSmall : null

  const getPrice = () => {
    if (type === PRODUCT_TYPES.VARIABLE) {
      // Get price from first variation
      return variations && variations.nodes && variations.nodes.length > 0
        ? variations.nodes[0].finalPrice
        : null
    } else {
      return price
    }
  }

  const getTags = () => {
    if (productTags && productTags.nodes && productTags.nodes.length > 0) {
      return productTags.nodes.reduce((acc, tag, index) => {
        return index === 0 ? tag.name : acc + ', ' + tag.name
      }, '')
    }
    return null
  }

  const getTextContent = () => {
    let txt = ''
    if (getTags()) txt += getTags()

    return txt
  }
  const getPriceContent = () => {
    return getPrice()
      ? `${formatPrice(getPrice())} ${getTranslation('singleProductPriceText')}`
      : ''
  }

  const handleCompareChange = () => {
    !productsToCompare.includes(productId)
      ? dispatch(addProductToCompare(productId))
      : dispatch(removeProductFromCompare(productId))
  }

  const handleCompareRemove = () => {
    if (productsToCompare.length > 2) {
      dispatch(removeProductFromCompare(productId))
    }
  }

  const handleClick = event => {
    event.preventDefault()
    if (!atComparePage) {
      if (!compareIsEnabled) {
        navigate(getUrl(uri))
      } else {
        handleCompareChange(productId)
      }
    }
  }

  return (
    productSerie && (
      <StyledProductCard atComparePage={atComparePage} onClick={handleClick}>
        {!atComparePage ? (
          <>
            {productImage ? (
              <StyledProductCard.ImageWrapper>
                <MakeImage makeImage={productImage} />
              </StyledProductCard.ImageWrapper>
            ) : (
              <StyledProductCard.Image src={noImageFallback} />
            )}
            {percentage && (
              <StyledProductCard.Percentage>
                {percentage}
              </StyledProductCard.Percentage>
            )}
          </>
        ) : (
          <>
            {productImage ? (
              <StyledProductCard.ImageWrapper>
                <MakeImage makeImage={productImage} />
              </StyledProductCard.ImageWrapper>
            ) : (
              <StyledProductCard.Image src={noImageFallback} />
            )}
            <Icon size={18} icon={'done'} handleClick={handleCompareRemove} />
          </>
        )}

        <StyledProductCard.Body>
          <Heading size={4} type={'h4'}>
            {name}
          </Heading>

          <Text className='description'>
            <span dangerouslySetInnerHTML={{ __html: getTextContent() }} />
          </Text>

          <Text className='price'>
            {product.acfAttributes.partialPayment && (
              <span>
                {product.acfAttributes.partialPayment}
                <br />
              </span>
            )}

            <span dangerouslySetInnerHTML={{ __html: getPriceContent() }} />
          </Text>
          {type === PRODUCT_TYPES.VARIABLE &&
            variations &&
            variations.nodes &&
            variations.nodes.length > 0 && (
              <ColorPicker small variations={variations.nodes} />
            )}

          {productStockStatus && productStockStatus.message && (
            <StyledProductCard.StockMessage>
              {productStockStatus.message}
            </StyledProductCard.StockMessage>
          )}

          {!atComparePage && (
            <StyledProductCard.Actions>
              {compareAllowed && compareIsEnabled && (
                <Form.ControlledCheckbox
                  label={getTranslation('compareText')}
                  id={id}
                  checked={productsToCompare.includes(productId)}
                />
              )}

              {!compareIsEnabled && (
                <Button href={getUrl(uri)} small active fullwidth={false}>
                  {getTranslation('readMoreUppercaseText')}
                </Button>
              )}
            </StyledProductCard.Actions>
          )}
        </StyledProductCard.Body>
      </StyledProductCard>
    )
  )
}

ProductCard.propTypes = {
  product: PropTypes.object,
  compareAllowed: PropTypes.bool,
  atComparePage: PropTypes.bool,
  percentage: PropTypes.string,
}

export { ProductCard }
