/* eslint-disable */
import styled, { css } from 'styled-components'
import { colors, spacing } from '../../styles/utilities/variables'

const parseColor = color => {
  const colors = {
    BLACK: '#000000',
    RED: '#CC0000',
    WHITE: '#FFFFFF',
    TURQUOISE: '#3BFDF1',
    SANDGOLD: '#F0D9AF',

    SVART: '#000000',
    VIT: '#FFFFFF',
    BRUN: '#8D4F01',
    BLÅ: '#0601D8',
    GRÅ: '#585858',
    SILVER: '#C0C0C0',
    TURKOS: '#01FFFF',
    RÖD: '#FF0100',
    CHAMPAGNE: '#FFECB0',
    ROSA: '#F4E2E3',

    TURQUOISE2: '#3BFDF1',
    SILVER2: '#C0C0C0',
    RED2: '#CC0000',
    BLACK2: '#000000',
    BLUE: '#0601D8',
    YELLOW: '#FFFF00',
    BROWN: '#8D4F01',
    GREY: '#585858',
    LIGHTBLUE: '#b8e2e9',
  }
  return colors[color]
}

const RadioButton = () => css`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: ${props => (props.small ? spacing.small : spacing.large)};
  height: ${props => (props.small ? spacing.small : spacing.large)};
  background: transparent;
  border: none;
  border-radius: 50%;
  background: ${props =>
    props.color ? parseColor(props.color) : colors.black};
  border: 1px solid
    ${props =>
      (props.color && props.color === 'WHITE' && '#DBDBDB') ||
      (props.color === 'VIT' && '#DBDBDB') ||
      (props.color && parseColor(props.color)) ||
      colors.black};

  &:not(:last-child) {
    margin-right: ${props => (props.small ? spacing.xSmall : spacing.small)};
  }
`

const StyledColorPicker = styled.div``
StyledColorPicker.Colors = styled.ul`
  list-style: none;
  display: flex;
  position: relative;
`

StyledColorPicker.Selected = styled.p`
  text-transform: uppercase;
`

StyledColorPicker.Label = styled.label`
  cursor: pointer;
  ${RadioButton}

  &:after {
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 50%;
    transition: opacity 0.3s;
  }
`

StyledColorPicker.Input = styled.input`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;

  &:checked {
    + label {
      border: 3px solid
        ${props =>
          (props.color && props.color === 'white' && '#DBDBDB') ||
          (props.color && parseColor(props.color)) ||
          colors.black};

      &:after {
        opacity: 1;
        border: 2px solid white;
        top: -2px;
        left: -2px;
      }
    }
  }
`

StyledColorPicker.Color = styled.li`
  ${RadioButton}
`

export default StyledColorPicker
